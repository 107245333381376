
import './App.css';
import { ConnectButton } from "360dialog-connect-button";

const App = () => {
//   const StyledConnectButton = styled(ConnectButton)`
//   outline: none;
//   background: #ff4369;
//   color: white;
//   padding: 8px 16px;
//   border-radius: 3px;
//   margin-top: 32px;
//   border: none;
// `;

  const handleCallback = (callbackObject) => {
    /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */

    console.log("client ID: "+callbackObject.client)
    console.log("channel IDs: " + callbackObject.channels);
  }

  return (
    <div>
      <ConnectButton 
        partnerId={'BLYHeePA'}
        // callback={handleCallback}
        callback={handleCallback}
		queryParameters={{
			redirect_url:"https://ads.yunzhangshi.cn/api/user/whatsapp"
		}}
		env="waba-sandbox"
      />
    </div>
  );
};
export default App;
